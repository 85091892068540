html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Helvetica, "Helvetica Neue", sans-serif;
}

:root {
  --primary-color: #47337e;
  --secondary-color: #ffff;
  --popup-border: #dedede;
  --file-icon-default: #95a5a6;
  --file-icon-image: #3498db;
  --file-icon-excel: #f39c12;
  --file-icon-word: #2ecc71;
  --file-icon-pdf: #e74c3c;
  --file-name: rgba(35, 35, 35, 1);
  --file-size: rgba(35, 35, 35, 0.6);
  --file-background-color: rgba(249, 249, 249, 1);
  --file-upload-box: #f0f8ff;
  --border-color: rgba(222, 222, 222, 1);
  --file-note: rgba(35, 35, 35, 0.6);
}

.form-field {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.4px;
  text-align: left;
  color: #232323;
}
// this is for mat dialog
.mdc-dialog--open .mat-mdc-dialog-surface,
.mdc-dialog--closing .mat-mdc-dialog-surface {
  transform: none;
  border-radius: 12px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #bdb9b9;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}
